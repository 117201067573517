import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { OfferState } from '../pages/policy-creator/store/offer.state';
import { PolicyCreatorState } from '../pages/policy-creator/store/policy-creator.state';

@Injectable()
export class SentryInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (environment.SENTRY_MONITORING) {
          const policyCreatorState = this.store.selectSnapshot(
            PolicyCreatorState.state,
          );
          const policyOfferState = this.store.selectSnapshot(OfferState.offer);

          Sentry.captureException(error, {
            data: {
              url: request.url,
              payload: request.body,
              error: error.error,
              policyCreatorState,
              policyOfferState,
            },
            captureContext: {
              extra: {
                url: request.url,
                payload: request.body,
                error: error.error,
                policyCreatorState,
                policyOfferState,
              },
            },
          });
        }
        return throwError(error);
      }),
    );
  }
}
