import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { LocaleSettings } from 'primeng/calendar';
import { Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DocumentBoxUploadBody } from '../cta/models/document-box-interface';
import { UploadedPolicyDocument } from '../cta/models/uploaded-policy-document.interface';
import { ClientTypesEnum } from './apk-summary-form-group/client-types.enum';
import { Button, CoverageNameEnum } from './models/button-interface';
import { CoverageInfo } from './models/coverage-info.interface';
import {
  DepreciationItem,
  DepreciationItemC,
  depreciationTable,
} from './models/depreciation-table.class';
import { Discount } from './models/discount-model';
import { ModelById } from './models/model-by-id.interface';
import {
  PaymentMethodEnum,
  PaymentMethodOption,
} from './models/payment-method-option.interface';
import {
  PaymentTermEnum,
  PaymentTermOption,
} from './models/payment-term-option.interface';
import {
  PolicyPriceCalculation,
  PolicyPriceCalculationC,
} from './models/policy-price-calculation';
import { SavePolicyCb } from './models/save-policy-cb.interface';
import { Policy, SavePolicy } from './models/save-policy.interface';
import { SignPolicy } from './models/sign-policy-interface';
import { VehicleMake, VehicleModel } from './models/vehicle-model.interface';

@Injectable({
  providedIn: 'root',
})
export class ApkService {
  apiBase = environment.apiBase;
  public apkForm: UntypedFormGroup;
  public step1Form: UntypedFormGroup;
  public summaryForm: UntypedFormGroup;
  public discountForm: UntypedFormGroup;
  public discountValue: number;
  public buttons: Button[];
  public selectedCoverage: CoverageInfo;
  private depreciationTable: DepreciationItem[] = depreciationTable;
  public calendarEn: LocaleSettings;
  public calendarPl: LocaleSettings;
  public savedPolicyCb: SavePolicyCb;
  public paymentMethodOptions: PaymentMethodOption[];
  public step: number;
  public apkMessageBox: string;
  now = moment();
  hasCalculationModal: boolean;
  hasVehicleValueCalculation: boolean;
  paymentTermOptions: PaymentTermOption[];
  insuranceTermOptions: number[];
  claimLimitOptions: number[];
  hasSummary: boolean;
  coveragePrice: number;
  formValidForCoverages: boolean;
  coveragesPendingRequest: boolean;
  coverages: CoverageInfo[];
  calculationDisabled: boolean;
  preview: boolean;
  loading: boolean;
  vehiclePurchaseDefaultDate: Date;
  vehiclePurchaseMinDate: Date;
  vehiclePurchaseMaxDate: Date;
  yearRange: string;
  saveCalculationForm: UntypedFormGroup;
  policyBuyDate: Date;
  valueChangesSubscription: Subscription;
  apkQuestionTwoComponentDestroyed: Subject<any>;
  reducedPrice: number;

  constructor(
    private transloco: TranslocoService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.calendarEn = {
      firstDayOfWeek: 0,
      dayNames: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      monthNamesShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Wk',
    };

    this.calendarPl = {
      firstDayOfWeek: 0,
      dayNames: [
        'Niedziela',
        'Poniedziałek',
        'Wtorek',
        'Środa',
        'Czwartek',
        'Piątek',
        'Sobota',
      ],
      dayNamesShort: ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pią', 'Sob'],
      dayNamesMin: ['N', 'P', 'W', 'Ś', 'C', 'P', 'S'],
      monthNames: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień',
      ],
      monthNamesShort: [
        'Sty',
        'Lut',
        'Mar',
        'Kwi',
        'Maj',
        'Cze',
        'Lip',
        'Sie',
        'Wrz',
        'Paź',
        'Lis',
        'Gru',
      ],
      today: 'Dziś',
      clear: 'Wyczyść',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Tyd',
    };
  }

  generateYearsSelectOptions(from: Date, n: number): { year: string }[] {
    const years: { year: string }[] = [];

    for (let i = 0; i < n; i++) {
      years.push({
        year: moment(from).subtract(i, 'year').format('YYYY'),
      });
    }

    return years;
  }

  resetApkFields(): void {
    this.reducedPrice = null;
    this.discountValue = null;
    this.coveragePrice = null;
    this.preview = null;
    this.loading = null;
  }

  initCalculationForm(): void {
    this.saveCalculationForm = this.fb.group({
      ...this.apkForm,
    });
  }

  initButtons(): Button[] {
    return [
      {
        name: CoverageNameEnum.GapInvoice,
        label: 'apk.mainForm.buttons.gapInvoice.label',
        labelSmall: 'apk.mainForm.buttons.gapInvoice.labelSmall',
        isActive: false,
        isVisible: false,
        info: 'apk.mainForm.buttons.gapInvoice.info',
        coverageName: 'MAX',
      },
      {
        name: CoverageNameEnum.TruckGapMax,
        label: 'apk.mainForm.buttons.truckGapMax.label',
        isActive: false,
        isVisible: false,
        info: 'apk.mainForm.buttons.truckGapMax.info',
        coverageName: 'Truck MAX',
      },
      {
        name: CoverageNameEnum.GapDirect,
        label: 'apk.mainForm.buttons.gapDirect.label',
        isActive: false,
        isVisible: false,
        info: 'apk.mainForm.buttons.gapDirect.info',
        coverageName: 'DIRECT',
      },
      {
        name: CoverageNameEnum.GapMaxAc,
        label: 'apk.mainForm.buttons.gapMaxAc.label',
        labelSmall: 'apk.mainForm.buttons.gapMaxAc.labelSmall',
        isActive: false,
        isVisible: false,
        info: 'apk.mainForm.buttons.gapMaxAc.info',
        coverageName: 'MAX AC',
      },
      {
        name: CoverageNameEnum.TruckGapMaxAc,
        label: 'apk.mainForm.buttons.truckGapMaxAc.label',
        isActive: false,
        isVisible: false,
        info: 'apk.mainForm.buttons.truckGapMaxAc.info',
        coverageName: 'Truck MAX AC',
      },
    ];
  }

  resetStep(): void {
    this.step = 0;
  }

  goToNextStep(): void {
    this.step = 1;
  }

  getPaymentMethodOptions(): void {
    this.paymentMethodOptions = [
      {
        value: PaymentMethodEnum.payuInstallments,
        label: 'payuInstallments',
      },
      {
        value: PaymentMethodEnum.payu,
        label: 'payu',
      },
      {
        value: PaymentMethodEnum.bankTransfer,
        label: 'bankTransfer',
      },
    ];
  }

  get getSelectedCoverage(): string {
    if (this.buttons.find((button) => button.isSelected)) {
      return this.buttons.find((button) => button.isSelected).coverageName;
    }
    return null;
  }

  getDepreciationTable(): DepreciationItem[] {
    return (this.depreciationTable = this.depreciationTable.map(
      (item) => new DepreciationItemC(item)
    ));
  }

  /**
   * Getting a vehicle price based on deprecation table.
   * @param vehicleAge An actual vehicle age in months.
   * @param vehiclePriceFromInsurance Last vehicle price from an actual policy
   */
  getVehiclePrice(
    vehicleAge: number,
    vehiclePriceFromInsurance: number
  ): string {
    const tableItem = this.getDepreciationTable().find(
      (deprecationItem) => deprecationItem.month === vehicleAge
    );

    if (tableItem && tableItem.beginningValue !== 0) {
      return (vehiclePriceFromInsurance * tableItem.beginningValue).toFixed(0);
    }

    if (vehicleAge === 0) {
      return vehiclePriceFromInsurance.toString();
    }
    return '-1';
  }

  /**
   * Calculates beginning value of the vehicle
   * based on policy buy date
   * and vehicle value from actual policy
   */
  getVehiclePriceAcInvoice(): number {
    /* Hack, due to form reset somewhere there is no actual policyBuyDate field so we bring it from another field from service */
    const { vehicleRegDate, valueFromActualPolicy } = this.apkForm.value;
    const tableItem = this.getDepreciationTable();
    let vehicleAgeUpToPolicyDateOfIssue: number;

    if (vehicleRegDate && this.policyBuyDate) {
      vehicleAgeUpToPolicyDateOfIssue = moment(this.policyBuyDate).diff(
        vehicleRegDate,
        'months'
      );
      console.log('vehicleAgeUpToPolicyDateOfIssue');
      console.log(vehicleAgeUpToPolicyDateOfIssue);
    }

    if (vehicleAgeUpToPolicyDateOfIssue && valueFromActualPolicy) {
      const depreciationTableItem = tableItem.find(
        (item) => item.month === vehicleAgeUpToPolicyDateOfIssue
      );
      if (depreciationTableItem && depreciationTableItem.beginningValue) {
        return Math.round(
          valueFromActualPolicy / depreciationTableItem.beginningValue
        );
      }
    }
  }

  getInsuranceTerms(): Observable<number[]> {
    return this.http.get<number[]>(`${this.apiBase}/policy/insuranceTerms/2`);
  }

  getClaimLimits(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiBase}/policy/claimLimits`);
  }

  getPaymentTerms(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiBase}/policy/paymentTerms/2`);
  }

  getDiscount(code: string): Observable<Discount> {
    return this.http.get<Discount>(`${this.apiBase}/discount/code/${code}`);
  }

  getCoverages(
    vehicleInfo: PolicyPriceCalculation
  ): Observable<CoverageInfo[]> {
    const params = new HttpParams({
      fromObject: {
        ...new PolicyPriceCalculationC(vehicleInfo),
      },
    });
    return this.http.get<CoverageInfo[]>(`${this.apiBase}/policy/coverages`, {
      params,
    });
  }

  getVehicleMakes(make: string): Observable<VehicleMake[]> {
    return this.http.get<VehicleMake[]>(`${this.apiBase}/policy/makes/${make}`);
  }

  getVehicleModels(
    id: string,
    make: string,
    model: string
  ): Observable<VehicleModel[]> {
    return this.http.get<VehicleModel[]>(
      `${this.apiBase}/policy/models/${id}/${make}/${model}`
    );
  }

  getPolicy(policyNo: string): Observable<Policy> {
    return this.http.get<Policy>(`${this.apiBase}/policy/${policyNo}`);
  }

  async savePolicy(payload: SavePolicy): Promise<SavePolicyCb> {
    console.log(payload);
    return this.http
      .post<SavePolicyCb>(`${this.apiBase}/policy/savePolicy`, { ...payload })
      .toPromise();
  }

  async saveQuote(payload: SavePolicy): Promise<SavePolicyCb> {
    console.log(payload);
    return this.http
      .post<SavePolicyCb>(`${this.apiBase}/policy/saveQuote`, { ...payload })
      .toPromise();
  }

  signPolicy(payload: SignPolicy): Observable<any> {
    return this.http.post<any>(`${this.apiBase}/policy/signPolicy`, {
      ...payload,
    });
  }

  getPolicyUploadedDocs(
    policyNo: string
  ): Observable<UploadedPolicyDocument[]> {
    return this.http.get<UploadedPolicyDocument[]>(
      `${this.apiBase}/policy/getPolicyUploadedDocs/${policyNo}`
    );
  }

  uploadPolicyDocuments(
    payload: DocumentBoxUploadBody
  ): Observable<UploadedPolicyDocument> {
    return this.http.post<UploadedPolicyDocument>(
      `${this.apiBase}/policy/uploadPolicyDocs`,
      { ...payload }
    );
  }

  removePolicyDocument(
    documentId: string
  ): Observable<UploadedPolicyDocument[]> {
    return this.http.delete<UploadedPolicyDocument[]>(
      `${this.apiBase}/policy/deletePolicyUploadedDoc/${documentId}`
    );
  }

  deactivateButtons(): void {
    this.buttons = this.initButtons();
  }

  get selectedCoverageBtn(): Button {
    return this.buttons.find((button) => button.isSelected);
  }

  checkPaymentTerms(invoiceVariant?: boolean): void {
    this.getPaymentTerms()
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.paymentTermOptions = res.map((r) => {
            return {
              label: r,
              value: r,
            } as PaymentTermOption;
          });
          this.paymentTermOptions.push({
            label: 'installments',
            value: PaymentTermEnum.LumpSum,
          });
          if (!invoiceVariant) {
            this.paymentTermOptions = this.paymentTermOptions.filter(
              (opt) => opt.label !== PaymentTermEnum.Annual
            );
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  async checkPaymentTermsPromise(invoiceVariant?: boolean): Promise<void> {
    await this.getPaymentTerms()
      .toPromise()
      .then((res) => {
        this.paymentTermOptions = res.map((r) => {
          return {
            label: r,
            value: r,
          } as PaymentTermOption;
        });
        this.paymentTermOptions.push({
          label: 'installments',
          value: PaymentTermEnum.LumpSum,
        });
        if (!invoiceVariant) {
          this.paymentTermOptions = this.paymentTermOptions.filter(
            (opt) => opt.label !== PaymentTermEnum.Annual
          );
        }
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  initSummaryForm(): void {
    const { policyData } = history.state;

    this.summaryForm = this.fb.group({
      customer: this.fb.group({
        hadClaims: [policyData ? policyData.customerHadClaims : false],
        email: [
          policyData ? policyData.customerEmail : null,
          [Validators.required, RxwebValidators.email()],
        ],
        ...this.initSummaryFormGroup('customer'),
      }),
      beneficiary: this.fb.group({
        isCoverholder: [
          policyData ? policyData.beneficiaryIsCoverholder : true,
        ],
        isInsured: [policyData ? policyData.beneficiaryIsInsured : true],
        email: [
          policyData ? policyData.beneficiaryEmail : null,
          [RxwebValidators.email()],
        ],
        ...this.initSummaryFormGroup('beneficiary', false),
      }),
      insured: this.fb.group({
        isCoverholder: [policyData ? policyData.insuredIsCoverholder : true],
        ...this.initSummaryFormGroup('insured', false),
      }),
      owner: this.fb.group({
        isCoverholder: [policyData ? policyData.ownerIsCoverholder : true],
        ...this.initSummaryFormGroup('owner', false),
      }),
      vehicleInfo: this.fb.group({
        make: [null, [Validators.required]],
        model: [null, [Validators.required]],
        intModelId: [
          policyData?.intModelId ? policyData.intModelId : null,
          [Validators.required],
        ],
        vehicleModelDerivative: [
          policyData?.vehicleModelDerivative
            ? policyData.vehicleModelDerivative
            : '',
          [Validators.required],
        ],
        vehiclePrevOwnersNo: [], // Possible values are 0, 1 ,2 ,3 (3 value means '3 or more').
        // Note: values 2 and 3 are unavailable to Poland users.
        // Note2: depending on that value, iDefend propably shows if car is new, 0 = new
        // Not shown in form
        vehicleRegNo: [
          policyData?.vehicleRegNo ? policyData.vehicleRegNo : null,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(8),
          ],
        ],
        vehicleVin: [
          policyData?.vehicleVin ? policyData.vehicleVin : null,
          [
            Validators.required,
            Validators.minLength(17),
            Validators.maxLength(17),
          ],
        ],
        isVehicleNew: [this.checkIfVehicleIsNew()], // Not required by iDefend, true if (reg Date < 120 d)
        vehicleOdometer: [
          policyData?.vehicleOdometer ? policyData.vehicleOdometer : null,
          [
            RxwebValidators.digit(),
            Validators.required,
            RxwebValidators.maxNumber({
              value: 5000,
              message: 'odometerToHigh',
              conditionalExpression: (x) => x.isVehicleNew === true,
            }),
          ],
        ],
        policyInceptionDate: [
          policyData?.policyInceptionDate
            ? new Date(policyData.policyInceptionDate)
            : new Date(),
        ],
        // Mandatory in combination MasterPolicy + GAP. Date have to be +- 14 days from today.
      }),
    });
  }

  checkIfVehicleIsNew(): boolean {
    const diff = moment(new Date()).diff(
      this.apkForm.get('vehicleRegDate').value,
      'days'
    );
    if (this.selectedCoverage && this.selectedCoverage.name === 'DIRECT') {
      return diff <= 365;
    }
    return diff <= 120;
  }

  initSummaryFormGroup(group: string, mandatory: boolean = true): any {
    if (history.state.step) {
      const { policyData } = history.state;

      return {
        type: [
          policyData[group + 'Type'],
          mandatory ? [Validators.required] : [],
        ],
        firstName: [
          policyData[group + 'FirstName'],
          [
            RxwebValidators.required({
              conditionalExpression: (x) => {
                return (
                  x.type === 'INDIVIDUAL' &&
                  (group === 'customer' || group === 'owner')
                );
              },
            }),
          ],
        ],
        lastName: [
          policyData[group + 'LastName'],
          [
            RxwebValidators.required({
              conditionalExpression: (x) => {
                return (
                  x.type === 'INDIVIDUAL' &&
                  (group === 'customer' || group === 'owner')
                );
              },
            }),
          ],
        ],
        companyName: [policyData[group + 'CompanyName']],
        idNo: [
          policyData[group + 'IdNo'],
          [
            RxwebValidators.required({
              conditionalExpression: (x) => {
                return (
                  x.type === 'BUSINESS' &&
                  (group === 'customer' || group === 'owner')
                );
              },
            }),
          ],
        ],
        address: [
          policyData[group + 'Address'],
          mandatory ? [Validators.required] : [],
        ],
        address2: [policyData[group + 'Address2']],
        postCode: [
          policyData[group + 'PostCode'],
          mandatory ? [Validators.required] : [],
        ],
        city: [
          policyData[group + 'City'],
          mandatory ? [Validators.required] : [],
        ],
        phoneRegion: [
          policyData[group + 'Tel']
            ? '+' + policyData[group + 'Tel'].toString().slice(0, 2)
            : null,
        ],
        phoneNumber: [
          policyData[group + 'Tel']
            ? policyData[group + 'Tel'].toString().substring(2)
            : null,
          group === 'customer'
            ? [Validators.required, RxwebValidators.digit()]
            : [RxwebValidators.digit()],
        ],
      };
    }

    return {
      type: [null, mandatory ? [Validators.required] : []], // Possible values are: 'INDIVIDUAL', 'SOLE_PROPRIETOR', 'BUSINESS'
      firstName: [null, mandatory ? [Validators.required] : []], // Mandatory only if if customer_type = 'INDIVIDUAL' or 'SOLE_PROPRIETOR'
      lastName: [null, mandatory ? [Validators.required] : []], // Mandatory only if if customer_type = 'INDIVIDUAL' or 'SOLE_PROPRIETOR'
      companyName: [null], // Mandatory only if if customer_type = 'BUSINESS'
      idNo: [null, mandatory ? [Validators.required] : []],
      address: [null, mandatory ? [Validators.required] : []],
      address2: [null],
      postCode: [null, mandatory ? [Validators.required] : []],
      city: [null, mandatory ? [Validators.required] : []],
      phoneRegion: ['+48'], // not required by iDefend
      phoneNumber: [
        null,
        group === 'customer'
          ? [Validators.required, RxwebValidators.digit()]
          : [RxwebValidators.digit()],
      ],
    };
  }

  getSavePolicyFields(): any {
    // vehicleInfo group fields
    const {
      vehicleModelDerivative,
      intModelId,
      vehicleOdometer,
      vehicleVin,
      vehicleRegNo,
      vehiclePrevOwnersNo,
      policyInceptionDate,
    } = this.summaryForm.get('vehicleInfo').value;

    // apkForm fields
    const {
      vehicleRegDate,
      vehiclePurchaseDate,
      vehiclePurchasePrice,
      vatIncluded,
      vatReclaimable,
      insTerm,
      paymentTerm,
      claimLimit,
      policyNo,
    } = this.apkForm.value;

    return {
      vehicleModelDerivative,
      intModelId,
      vehicleRegDate: moment(vehicleRegDate).format('YYYY-MM-DD'),
      vehiclePurchaseDate: moment(vehiclePurchaseDate).format('YYYY-MM-DD'),
      vehiclePurchasePrice,
      vehicleOdometer,
      vehicleVin,
      vehicleRegNo,
      vehiclePrevOwnersNo,
      vatIncluded,
      vatReclaimable,
      policyInceptionDate: policyInceptionDate
        ? moment(policyInceptionDate).format('YYYY-MM-DD')
        : null,
      insTerm,
      paymentTerm: paymentTerm ? paymentTerm.value : null,
      claimLimit,
      ...this.getContactFields(this.summaryForm.get('customer'), 'customer'),
      ...this.getContactFields(
        this.summaryForm.get('beneficiary'),
        'beneficiary'
      ),
      ...this.getContactFields(this.summaryForm.get('insured'), 'insured'),
      ...this.getContactFields(this.summaryForm.get('owner'), 'owner'),
      paymentMethod: this.apkForm.get('paymentMethod').value.value,
      policyNo,
    };
  }

  getContactFields(formGroup, formGroupName: string): any {
    const {
      type,
      isCoverholder,
      isInsured,
      firstName,
      lastName,
      companyName,
      idNo,
      address,
      address2,
      postCode,
      city,
      phoneRegion,
      phoneNumber,
      hadClaims,
      email,
    } = formGroup.value;

    if (formGroupName === 'customer') {
      return {
        customerType: !type ? ClientTypesEnum.individual : type,
        customerFirstName: firstName,
        customerLastName: lastName,
        customerCompanyName: companyName,
        customerIdNo: idNo,
        customerAddress: address,
        customerAddress2: address2,
        customerCity: city,
        customerPostCode: postCode,
        customerTel: phoneRegion + phoneNumber,
        customerEmail: email,
        customerHadClaims: hadClaims,
      };
    }

    if (formGroupName === 'beneficiary') {
      return {
        beneficiaryType: !type ? ClientTypesEnum.individual : type,
        beneficiaryIsCoverholder: isCoverholder,
        beneficiaryIsInsured: isInsured,
        beneficiaryFirstName: firstName,
        beneficiaryLastName: lastName,
        beneficiaryCompanyName: companyName,
        beneficiaryIdNo: idNo,
        beneficiaryAddress: address,
        beneficiaryAddress2: address2,
        beneficiaryCity: city,
        beneficiaryPostCode: postCode,
        beneficiaryTel: phoneRegion + phoneNumber,
        beneficiaryEmail: email,
        beneficiaryHadClaims: hadClaims,
      };
    }

    if (formGroupName === 'insured') {
      return {
        insuredType: !type ? ClientTypesEnum.individual : type,
        insuredIsCoverholder: isCoverholder,
        insuredFirstName: firstName,
        insuredLastName: lastName,
        insuredCompanyName: companyName,
        insuredIdNo: idNo,
        insuredAddress: address,
        insuredAddress2: address2,
        insuredCity: city,
        insuredPostCode: postCode,
        insuredTel: phoneRegion + phoneNumber,
        insuredEmail: email,
        insuredHadClaims: hadClaims,
      };
    }

    if (formGroupName === 'owner') {
      return {
        ownerType: !type ? ClientTypesEnum.individual : type,
        ownerIsCoverholder: isCoverholder,
        ownerFirstName: firstName,
        ownerLastName: lastName,
        ownerCompanyName: companyName,
        ownerIdNo: idNo,
        ownerAddress: address,
        ownerAddress2: address2,
        ownerCity: city,
        ownerPostCode: postCode,
        ownerTel: phoneRegion + phoneNumber,
        ownerEmail: email,
        ownerHadClaims: hadClaims,
      };
    }
  }

  getModelById(id: string): Observable<ModelById> {
    return this.http.post<ModelById>(`${this.apiBase}/policy/getModelById`, {
      modelId: id,
    });
  }

  resetForms(hard = false): void {
    if (hard) {
      this.apkForm?.reset();
      this.summaryForm?.reset();
      this.step1Form?.reset();
      this.resetStep();
      this.buttons = this.initButtons();
      this.preview = false;

      this.selectedCoverage = null;
      this.savedPolicyCb = null;
      this.hasSummary = false;
      this.coveragePrice = null;
    } else {
      this.apkForm = this.fb.group(this.step1Form.controls);
    }
    this.hasCalculationModal = false;
    this.hasVehicleValueCalculation = false;
  }

  setFormsPreviewOnly(): boolean {
    return !!(this.savedPolicyCb && this.savedPolicyCb.statusLockedOn);
  }

  handleDirectRegistrationDateLogic(selectedYear: any): void {
    const selectedDate = moment(selectedYear.value.year, 'YYYY');
    this.apkForm.get('vehicleRegDate').patchValue(selectedDate.toDate());
    if (this.summaryForm) {
      const vehicleInfo = this.summaryForm.get('vehicleInfo');
      vehicleInfo.get('isVehicleNew').patchValue(this.checkIfVehicleIsNew());
    }
  }

  /* Step1 logic */
  handleRegistrationDateLogic(destroyed: Subject<any>): void {
    if (history.state.step) {
      this.preview = this.setFormsPreviewOnly();
    }
    const { vehicleRegDate } = this.apkForm.value;
    const diff = this.now.diff(moment(vehicleRegDate), 'days');

    this.resetForms();

    setTimeout(() => {
      if (diff < 120) {
        this.handleT1Logic();
      } else {
        this.handleF1Logic(diff, destroyed);
      }
    });
  }

  handleT1Logic(): void {
    const { vehicleWeight } = this.step1Form.value;

    if (!vehicleWeight || vehicleWeight < 3501) {
      // activate GapInvoice and GapDirect buttons
      this.buttons
        .filter(
          (item) =>
            item.name === CoverageNameEnum.GapInvoice ||
            item.name === CoverageNameEnum.GapDirect
        )
        .map((button) => {
          button.isActive = !this.preview;
          button.isVisible = true;
        });
    } else {
      this.buttons
        .filter((item) => item.name === CoverageNameEnum.TruckGapMax)
        .map((button) => {
          button.isActive = !this.preview;
          button.isVisible = true;
        });
    }
  }

  handleF1Logic(diff: number, destroyed: Subject<any>): void {
    const { vehicleRegDate } = this.apkForm.value;
    const years = this.now.diff(moment(vehicleRegDate), 'years');

    if (years > 5) {
      this.handleT2Logic();
    } else {
      this.handleF2Logic(diff, destroyed);
    }
  }

  handleT2Logic(): void {
    const { vehicleWeight } = this.step1Form.value;

    if (vehicleWeight < 3501 || !vehicleWeight) {
      this.buttons
        .filter((item) => item.name === CoverageNameEnum.GapDirect)
        .map((button) => {
          button.isActive = !this.preview;
          button.isVisible = true;
        });
    } else {
      this.handleOutOfDateForAvailableVariants();
    }
  }

  handleF2Logic(diff: number, destroyed: Subject<any>): void {
    this.handleF3Logic(destroyed);
  }

  handleT3Logic(destroyed: Subject<any>, moreThanYearVariant?: boolean): void {
    const { vehicleWeight } = this.step1Form.value;

    if (vehicleWeight < 3501 || !vehicleWeight) {
      this.buttons
        .filter(
          (item) =>
            item.name === CoverageNameEnum.GapMaxAc ||
            item.name === CoverageNameEnum.GapDirect
        )
        .map((button) => {
          if (button.name === CoverageNameEnum.GapMaxAc) {
            button.isVisible = true;
            button.isActive = !this.preview;
            button.isSelected = true;
          }

          if (button.name === CoverageNameEnum.GapDirect) {
            button.isActive = !this.preview;
            button.isVisible = true;
          }
        });

      // move direct button to end of array of buttons
      this.buttons
        .filter((visible) => visible.isVisible)
        .map((item) => {
          if (item.name === CoverageNameEnum.GapDirect) {
            this.buttons.push(
              this.buttons.splice(this.buttons.indexOf(item), 1)[0]
            );
          }
        });

      this.handleT3ChooseVariant('light', destroyed, moreThanYearVariant);
    } else {
      this.buttons
        .filter(
          (item) =>
            item.name === CoverageNameEnum.TruckGapMaxAc ||
            item.name === CoverageNameEnum.GapDirect ||
            item.name === CoverageNameEnum.GapMaxAc
        )
        .map((button) => {
          if (button.name === CoverageNameEnum.TruckGapMaxAc) {
            button.isActive = !this.preview;
            button.isVisible = true;
            button.isSelected = true;
          }

          if (
            button.name === CoverageNameEnum.GapDirect ||
            button.name === CoverageNameEnum.GapMaxAc
          ) {
            button.isActive = false;
            button.isVisible = false;
          }
        });
      this.handleT3ChooseVariant('heavy', destroyed, moreThanYearVariant);
    }
  }

  handleF3Logic(destroyed: Subject<any>): void {
    this.initCommonFormFields();
    this.goToNextStep();

    this.apkForm.addControl(
      'hasActualPolicy',
      this.fb.control(null, [Validators.required])
    );
    this.confirmationService.confirm({
      message: `<h1>${this.transloco.translate(
        'apk.mainForm.inputLabels.popupTitle.1'
      )}
          <span>${this.transloco.translate(
            'apk.mainForm.inputLabels.popupTitle.2'
          )}</span></h1>
          <p>${this.transloco.translate(
            'apk.mainForm.inputLabels.hasActualPolicy'
          )}</p>`,
      key: 'hasActualPolicy',
      acceptLabel: this.transloco.translate('buttons.yes'),
      rejectLabel: this.transloco.translate('buttons.no'),
      accept: () => {
        this.handleActualPolicyChange(true, destroyed);
      },
      reject: () => {
        this.handleActualPolicyChange(false, destroyed);
      },
    });
  }

  handleT3ChooseVariant(
    type,
    destroyed: Subject<any>,
    moreThanYearVariant?: boolean
  ): void {
    if (!moreThanYearVariant) {
      this.initCommonFormFields();
    }
    this.goToNextStep();

    if (this.preview) {
      const selectedButton = this.buttons.find((b) => b.isSelected);
      this.handleGapVariantChoose(selectedButton, destroyed);
      return;
    }

    if (type === 'light') {
      this.confirmationService.confirm({
        message: `<h1>${this.transloco.translate(
          'apk.mainForm.inputLabels.popupTitle.1'
        )}
          <span>${this.transloco.translate(
            'apk.mainForm.inputLabels.popupTitle.2'
          )}</span></h1>
          <div class="container">
          <p>${this.transloco.translate(
            moreThanYearVariant
              ? 'apk.mainForm.inputLabels.popupContent.moreThan30days.1'
              : 'apk.mainForm.inputLabels.popupContent.1T3'
          )}</p>
          <p class="p-text-bold">${this.transloco.translate(
            moreThanYearVariant
              ? 'apk.mainForm.inputLabels.popupContent.moreThan30days.2'
              : 'apk.mainForm.inputLabels.popupContent.2T3'
          )}</p>
          <p>${this.transloco.translate(
            'apk.mainForm.inputLabels.popupContent.moreThan30days.3'
          )}</p>
          <p><span class="textYellow p-text-bold">${this.transloco.translate(
            'apk.mainForm.inputLabels.popupContent.moreThan30days.3a'
          )}</span>.</p>
          <p>${this.transloco.translate(
            'apk.mainForm.inputLabels.popupContent.moreThan30days.4'
          )}</p></div>`,
        key: 'T3chooseVariant',
        accept: () => {
          this.buttons
            .filter((b) => b.isActive)
            .map((b) => {
              if (b.name === 'GapMaxAc') {
                b.isSelected = true;
              }
            });

          this.confirmationService.close();

          this.apkForm.addControl(
            'valueFromInvoice',
            this.fb.control(null, [
              Validators.required,
              RxwebValidators.digit(),
              RxwebValidators.maxNumber({ value: 600000 }),
            ])
          );

          this.apkForm.addControl(
            'vehicleValueCalculation',
            this.fb.control(null)
          );
          this.hasVehicleValueCalculation = true;
        },
        reject: () => {
          this.handleGapVariantChoose(
            this.buttons.find(
              (item) => item.name === CoverageNameEnum.GapDirect
            ),
            destroyed
          );
        },
      });
    } else {
      this.confirmationService.close();
      this.apkForm.addControl('vehicleValueCalculation', this.fb.control(null));
      this.hasVehicleValueCalculation = true;
    }
  }

  /* This method manages form views in apk form
     switching between buttons should show different forms in some cases */
  handleGapVariantChoose(
    button: Button,
    destroyed: Subject<any>,
    formEvent?: boolean
  ): void {
    const { insTerm, claimLimit } = this.apkForm.value;

    this.hasCalculationModal = true;
    this.hasVehicleValueCalculation = false;
    this.buttons.filter((b) => b !== button).map((b) => (b.isSelected = false));

    if (formEvent && button.isSelected) {
      return;
    }

    button.isSelected = true;

    switch (button.name) {
      case CoverageNameEnum.GapInvoice:
      case CoverageNameEnum.TruckGapMax: {
        this.handleVehiclePurchaseDateRange();
        this.getInsuranceTerms();
        this.checkClaimLimits();
        this.checkPaymentTerms(true);

        this.initCommonFormFields();

        this.apkMessageBox = this.transloco.translate(
          'apk.mainForm.messages.q2v1t.gapInvoice'
        );
        break;
      }

      case CoverageNameEnum.GapDirect: {
        this.checkClaimLimits();
        this.checkPaymentTerms();

        // this.insuranceTermOptions = this.insuranceTermOptions.slice(0, 3);

        this.apkForm = this.fb.group({
          ...this.step1Form.controls,
          vehiclePurchasePrice: ['1', [Validators.required]],
          claimLimit: [claimLimit ? claimLimit : null, [Validators.required]],
          insTerm: [insTerm ? insTerm : null, [Validators.required]],
          paymentTerm: [
            { value: null, disabled: false },
            [Validators.required],
          ],
          paymentMethod: [
            { value: null, disabled: false },
            [Validators.required],
          ],
          policyNo: [''],
        });

        this.apkMessageBox = this.transloco.translate(
          'apk.mainForm.messages.q2v1t.gapDirect'
        );
        break;
      }

      case CoverageNameEnum.GapMaxAc:
      case CoverageNameEnum.TruckGapMaxAc: {
        this.handleVehiclePurchaseDateRange();
        this.checkClaimLimits();
        this.checkPaymentTerms(true);

        const vehicleRegDateDiff = moment().diff(
          this.apkForm.get('vehicleRegDate').value,
          'days'
        );

        if (vehicleRegDateDiff > 365) {
          this.handleF3Logic(this.apkQuestionTwoComponentDestroyed);
        } else {
          this.handleCascoForm();
        }

        this.apkMessageBox = this.transloco.translate(
          'apk.mainForm.messages.q2v4.cascoMsg'
        );
        break;
      }
    }

    this.subscribeForCoveragesWhenFormValid(destroyed);
    this.hasSummary = false;
    this.coveragePrice = null;
  }

  handleVehiclePurchaseDateRange(minDate?: Date, maxDate?: Date): void {
    const selectedBtn = this.buttons.find((b) => b.isSelected);

    switch (selectedBtn.name) {
      case CoverageNameEnum.GapInvoice:
      case CoverageNameEnum.TruckGapMax:
      case CoverageNameEnum.GapMaxAc:
      case CoverageNameEnum.TruckGapMaxAc: {
        // if minDate and MaxDate specified then return range from params
        if (minDate && maxDate) {
          console.log(this.apkForm.value);
          this.vehiclePurchaseMinDate = minDate;
          this.vehiclePurchaseMaxDate = maxDate;
          this.vehiclePurchaseDefaultDate = maxDate;
        }
        // else return date range from vehicleRegDate
        else if (this.step1Form && this.step1Form.get('vehicleRegDate').value) {
          console.log(this.apkForm.value);
          const vehicleRegDate = moment(
            this.step1Form.get('vehicleRegDate').value
          );
          this.vehiclePurchaseMinDate = vehicleRegDate.add(-14, 'd').toDate();
          this.vehiclePurchaseMaxDate = moment().toDate();
          this.vehiclePurchaseDefaultDate = this.vehiclePurchaseMaxDate;
        }

        this.yearRange =
          moment(this.vehiclePurchaseMinDate).format('YYYY') +
          ':' +
          moment(this.vehiclePurchaseMaxDate).format('YYYY');
        break;
      }
    }
  }

  get getSelectedButton(): Button {
    const selectedButton = this.buttons.find((b) => b.isSelected);
    if (selectedButton) {
      return selectedButton;
    }
    return null;
  }

  initCommonFormFields(): void {
    const {
      vehiclePurchaseDate,
      vehiclePurchasePrice,
      vatIncluded,
      insTerm,
      claimLimit,
      vatReclaimable,
    } = this.apkForm.value;

    this.apkForm = this.fb.group({
      ...this.step1Form.controls,
      vehiclePurchaseDate: [
        vehiclePurchaseDate ? vehiclePurchaseDate : null,
        [Validators.required],
      ],
      vehiclePurchasePrice: [
        vehiclePurchasePrice ? vehiclePurchasePrice : null,
        [
          Validators.required,
          RxwebValidators.digit(),
          RxwebValidators.maxNumber({ value: 600000 }),
        ],
      ],
      vatIncluded: [
        vatIncluded !== undefined ? vatIncluded : null,
        [Validators.required],
      ],
      vatReclaimable: [
        vatReclaimable ? vatReclaimable : null,
        [Validators.required],
      ],
      insTerm: [insTerm ? insTerm : null, [Validators.required]],
      claimLimit: [claimLimit ? claimLimit : null, [Validators.required]],
      paymentTerm: [{ value: null, disabled: false }, [Validators.required]],
      paymentMethod: [{ value: null, disabled: false }, [Validators.required]],
      policyNo: [''],
    });

    const selectedBtn = this.buttons.find((b) => b.isSelected);
    if (
      selectedBtn &&
      (selectedBtn.name === 'GapInvoice' || selectedBtn.name === 'TruckGapMax')
    ) {
      this.apkForm.addControl(
        'valueFromInvoice',
        this.fb.control(null, [
          Validators.required,
          RxwebValidators.digit(),
          RxwebValidators.maxNumber({ value: 600000 }),
        ])
      );

      this.apkForm.addControl(
        'valueFromActualPolicy',
        this.fb.control(null, [
          Validators.required,
          RxwebValidators.digit(),
          RxwebValidators.maxNumber({ value: 600000 }),
        ])
      );
    }
  }

  handleBoughtLast4Months(confirm: boolean): void {
    this.deactivateButtons();
    this.apkForm.get('boughtLast4Months').patchValue(confirm);
    const { vehicleWeight } = this.step1Form.value;

    if (confirm) {
      this.buttons
        .filter(
          (b) =>
            b.name === CoverageNameEnum.GapInvoice ||
            b.name === CoverageNameEnum.GapDirect ||
            b.name === CoverageNameEnum.TruckGapMax
        )
        .map((b) => {
          if (vehicleWeight < 3501 || !vehicleWeight) {
            if (b.name === CoverageNameEnum.GapInvoice) {
              b.isVisible = true;
              b.isSelected = true;
              b.isActive = true;
            }
            if (b.name === CoverageNameEnum.GapDirect) {
              b.isVisible = true;
              b.isSelected = false;
              b.isActive = true;
            }
          } else {
            if (b.name === CoverageNameEnum.TruckGapMax) {
              b.isVisible = true;
              b.isSelected = true;
              b.isActive = true;
            }
          }
        });

      this.apkForm.get('hasActualPolicy').patchValue(true);

      this.apkForm.addControl(
        'valueFromInvoice',
        this.fb.control(null, [
          Validators.required,
          RxwebValidators.digit(),
          RxwebValidators.maxNumber({ value: 600000 }),
        ])
      );

      this.apkForm.addControl(
        'valueFromActualPolicy',
        this.fb.control(null, [
          Validators.required,
          RxwebValidators.digit(),
          RxwebValidators.maxNumber({ value: 600000 }),
        ])
      );

      this.hasCalculationModal = true;
      this.hasVehicleValueCalculation = false;

      this.handleVehiclePurchaseDateRange(
        moment().subtract('4', 'months').toDate(),
        moment().toDate()
      );

      // this.subscribeForCoveragesWhenFormValid(destroyed);
      this.checkClaimLimits();
      this.checkPaymentTerms(true);
    } else {
      this.boughtMoreThanLast3MonthsForm();

      if (vehicleWeight < 3501 || !vehicleWeight) {
        this.buttons
          .filter(
            (b) =>
              b.name === CoverageNameEnum.GapMaxAc ||
              b.name === CoverageNameEnum.GapDirect
          )
          .map((b) => {
            b.isVisible = true;
            b.isActive = !this.preview;
            if (b.name === CoverageNameEnum.GapMaxAc) {
              b.isSelected = true;
            }
          });
      } else {
        this.buttons
          // .filter(b => b.name === CoverageNameEnum.GapMaxAc)
          .filter((b) => b.name === CoverageNameEnum.TruckGapMaxAc)
          .map((b) => {
            b.isVisible = true;
            b.isActive = !this.preview;
            b.isSelected = true;
          });
      }

      this.handleVehiclePurchaseDateRange(
        moment().subtract('365', 'days').toDate(),
        moment().toDate()
      );

      this.apkForm.get('hasActualPolicy').patchValue(true);
      // this.subscribeForCoveragesWhenFormValid(destroyed);
    }

    // move direct button to end of array of buttons
    this.buttons
      .filter((visible) => visible.isVisible)
      .map((item) => {
        if (item.name === CoverageNameEnum.GapDirect) {
          this.buttons.push(
            this.buttons.splice(this.buttons.indexOf(item), 1)[0]
          );
        }
      });
  }

  boughtMoreThanLast3MonthsForm(): void {
    const { valueFromActualPolicy, policyBuyDate } = this.apkForm.value;

    if (this.apkForm.get('vehiclePurchaseDate')) {
      this.apkForm.removeControl('vehiclePurchaseDate');
    }

    this.apkForm = this.fb.group({
      ...this.apkForm.controls,
      valueFromActualPolicy: [
        valueFromActualPolicy ? valueFromActualPolicy : null,
        [
          Validators.required,
          RxwebValidators.digit(),
          RxwebValidators.maxNumber({ value: 600000 }),
        ],
      ],
      policyBuyDate: [policyBuyDate ? policyBuyDate : null],
    });
  }

  handleActualPolicyChange(state: boolean, destroyed: Subject<any>): void {
    this.deactivateButtons();

    if (state) {
      this.apkForm.addControl(
        'boughtLast4Months',
        this.fb.control(null, [Validators.required])
      );

      this.confirmationService.confirm({
        message: `<h1>${this.transloco.translate(
          'apk.mainForm.inputLabels.popupTitle.1'
        )}
          <span>${this.transloco.translate(
            'apk.mainForm.inputLabels.popupTitle.2'
          )}</span></h1>
          <p>${this.transloco.translate(
            'apk.mainForm.inputLabels.boughtLast4Months'
          )}</p>`,
        key: 'boughtLast4Months',
        acceptLabel: this.transloco.translate('buttons.yes'),
        rejectLabel: this.transloco.translate('buttons.no'),
        accept: () => {
          this.handleBoughtLast4Months(true);
        },
        reject: () => {
          this.handleBoughtLast4Months(false);
          // this.handleContactDialog();
        },
      });
    } else {
      const { vehicleWeight } = this.step1Form.value;

      if (vehicleWeight <= 3500) {
        this.buttons
          .filter((b) => b.name === CoverageNameEnum.GapDirect)
          .map((b) => {
            b.isSelected = true;
            b.isActive = !this.preview;
            b.isVisible = true;
          });

        this.handleGapVariantChoose(
          this.buttons.find((b) => b.name === CoverageNameEnum.GapDirect),
          destroyed
        );
        this.apkForm.removeControl('boughtLast4Months');
      } else {
        this.handleContactDialog();
      }
    }
  }

  handleContactDialog(): void {
    this.confirmationService.confirm({
      message: `<h1>${this.transloco.translate(
        'apk.mainForm.inputLabels.popupTitle.1'
      )}
          <span>${this.transloco.translate(
            'apk.mainForm.inputLabels.popupTitle.2'
          )}</span></h1>
          ${this.transloco.translate(
            'apk.mainForm.inputLabels.boughtLast4MonthsNegative'
          )}`,
      key: 'contactDialog',
      acceptLabel: this.transloco.translate(
        'apk.mainForm.inputLabels.boughtLast4MonthsContact'
      ),
      rejectVisible: false,
      accept: () => {
        this.router.navigateByUrl('/#contact');
      },
    });
  }

  handleOutOfDateForAvailableVariants(): void {
    this.confirmationService.confirm({
      message: `<h1>${this.transloco.translate(
        'apk.mainForm.inputLabels.popupTitle.1'
      )}
          <span>${this.transloco.translate(
            'apk.mainForm.inputLabels.popupTitle.2'
          )}</span></h1>
          ${this.transloco.translate(
            'apk.mainForm.inputLabels.boughtOver6YearsAgoPositive'
          )}`,
      key: 'tooOld',
      acceptLabel: this.transloco.translate(
        'apk.mainForm.inputLabels.boughtOver6YearsAgoContact'
      ),
      rejectVisible: false,
      accept: () => {
        // this.router.navigateByUrl('/#home');
      },
    });
  }

  handleChooseVariant(type, destroyed: Subject<any>): void {
    this.confirmationService.confirm({
      message: `<h1>${this.transloco.translate(
        'apk.mainForm.inputLabels.popupTitle.1'
      )}
          <span>${this.transloco.translate(
            'apk.mainForm.inputLabels.popupTitle.2'
          )}</span></h1>
          <div class="container">
          <p>${this.transloco.translate(
            'apk.mainForm.inputLabels.popupContent.1'
          )}</p>
          <p class="p-text-bold">${this.transloco.translate(
            'apk.mainForm.inputLabels.popupContent.2'
          )}</p>
          <p>${this.transloco.translate(
            'apk.mainForm.inputLabels.popupContent.3'
          )}</p>
          <p class="textYellow p-text-bold">${this.transloco.translate(
            'apk.mainForm.inputLabels.popupContent.4'
          )}</p>
          <p>${this.transloco.translate(
            'apk.mainForm.inputLabels.popupContent.5'
          )}</p></div>`,
      key: 'chooseVariant',
      accept: () => {
        if (type === 'light') {
          this.handleGapVariantChoose(
            this.buttons.find(
              (item) => item.name === CoverageNameEnum.GapMaxAc
            ),
            destroyed
          );
        } else {
          this.handleGapVariantChoose(
            this.buttons.find(
              (item) => item.name === CoverageNameEnum.TruckGapMaxAc
            ),
            destroyed
          );
        }
      },
      reject: () => {
        if (type === 'light') {
          this.handleGapVariantChoose(
            this.buttons.find(
              (item) => item.name === CoverageNameEnum.GapDirect
            ),
            destroyed
          );
        }
      },
    });
  }

  checkClaimLimits(): void {
    const selectedButton = this.buttons.find((button) => button.isSelected);

    this.claimLimitOptions = [];

    this.getClaimLimits()
      .pipe(take(1))
      .subscribe(
        (res) => {
          const response = res
            .map((limit) => parseInt(limit, 10))
            .sort((a, b) => a - b);

          if (selectedButton && selectedButton.name !== 'GapDirect') {
            if (response.length > 3) {
              this.claimLimitOptions = response.slice(-3);
            }
          } else {
            this.claimLimitOptions = response.slice(0, 4);
          }

          if (history.state.step && history.state.policyData.claimLimit) {
            this.apkForm
              .get('claimLimit')
              .setValue(history.state.policyData.claimLimit);
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  checkCoverages(): void {
    if (!this.coveragesPendingRequest) {
      this.coveragesPendingRequest = true;
      setTimeout(() => {
        const subscription = this.getCoverages(this.apkForm.value)
          .pipe(take(1))
          .subscribe(
            (res: CoverageInfo[]) => {
              this.coverages = res;
              this.coveragesPendingRequest = false;
              subscription.unsubscribe();
              // this.calculatedPrice.emit(this.coverages);
              this.findCoverageCalculation();
            },
            (err: HttpErrorResponse) => {
              console.log(err);
            }
          );
      });
    }
  }

  /* Subscribes for coverage price when form is valid to get valuation */
  subscribeForCoveragesWhenFormValid(destroyed: Subject<any>): void {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }

    this.valueChangesSubscription = this.apkForm.valueChanges
      .pipe(takeUntil(destroyed))
      .subscribe(() => {
        setTimeout(() => {
          console.log(this.apkForm.value);
          this.resetSelectedCoverage();
          if (this.validateVehicleInfo()) {
            this.checkCoverages();
          }
        });
      });
  }

  calculateVehiclePrice(): void {
    const { vehiclePurchasePrice } = this.apkForm.controls;
    const { vehicleRegDate, valueFromInvoice, valueFromActualPolicy } =
      this.apkForm.value;

    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
      this.valueChangesSubscription = null;
    }

    if (
      this.getSelectedButton.name === 'GapInvoice' ||
      this.getSelectedButton.name === 'GapMaxAc' ||
      this.getSelectedButton.name === 'TruckGapMaxAc' ||
      this.getSelectedButton.name === 'TruckGapMax'
    ) {
      if (valueFromInvoice < valueFromActualPolicy) {
        vehiclePurchasePrice.patchValue(valueFromInvoice);
      } else {
        vehiclePurchasePrice.patchValue(valueFromActualPolicy);
      }
    }

    /** Getting vehicle value calculation from policy value */
    if (
      this.apkForm.get('vehicleValueCalculation') &&
      this.hasVehicleValueCalculation
    ) {
      const vehicleRegDateDiff = this.now.diff(
        moment(vehicleRegDate),
        'months'
      );

      if (vehicleRegDateDiff < 12) {
        /* New Car vehicle depretiation table calulation */
        const vehiclePrice = this.getVehiclePrice(
          vehicleRegDateDiff,
          vehiclePurchasePrice.value
        );
        // console.log('vehicleRegDateDiff');
        // console.log(vehicleRegDateDiff);
        // console.log('vehiclePrice');
        // console.log(vehiclePrice);

        if (vehiclePurchasePrice.value <= 600000) {
          this.apkForm.get('vehicleValueCalculation').patchValue(vehiclePrice);
        } else {
          this.apkForm.get('vehicleValueCalculation').patchValue(null);
        }
      } else {
        /* Used Car vehicle depretiation table calulation */
        console.log('* Used Car vehicle depretiation table calulation *');
        const vehicleBeginningValue = this.getVehiclePriceAcInvoice();
        // console.log('vehicleBeginningValue');
        // console.log(vehicleBeginningValue);
        // console.log('vehicleRegDateDiff');
        // console.log(vehicleRegDateDiff);

        if (vehicleBeginningValue && vehicleRegDateDiff) {
          this.apkForm
            .get('vehicleValueCalculation')
            .patchValue(
              this.getVehiclePrice(vehicleRegDateDiff, vehicleBeginningValue)
            );
          this.apkForm
            .get('vehiclePurchasePrice')
            .patchValue(
              this.getVehiclePrice(vehicleRegDateDiff, vehicleBeginningValue)
            );
        }
      }
    }

    this.subscribeForCoveragesWhenFormValid(
      this.apkQuestionTwoComponentDestroyed
    );
  }

  handleCascoForm(): void {
    this.showMaxAcCascoForm();
  }

  showMaxAcCascoForm(): Promise<void> {
    const {
      vehiclePurchaseDate,
      vehiclePurchasePrice,
      vatIncluded,
      insTerm,
      claimLimit,
      valueFromActualPolicy,
    } = this.apkForm.value;

    this.buttons.find((button) => button.isSelected).isActive = !this.preview;

    this.apkForm = this.fb.group({
      ...this.apkForm.controls,
      vehiclePurchaseDate: [
        vehiclePurchaseDate ? vehiclePurchaseDate : null,
        [Validators.required],
      ],
      vehiclePurchasePrice: [
        vehiclePurchasePrice ? vehiclePurchasePrice : null,
        [
          Validators.required,
          RxwebValidators.digit(),
          RxwebValidators.maxNumber({ value: 600000 }),
        ],
      ],
      valueFromActualPolicy: [
        valueFromActualPolicy ? valueFromActualPolicy : null,
        [
          Validators.required,
          RxwebValidators.digit(),
          RxwebValidators.maxNumber({ value: 600000 }),
        ],
      ],
      vatIncluded: [
        vatIncluded !== undefined ? vatIncluded : null,
        [Validators.required],
      ],
      vatReclaimable: [null, [Validators.required]],
      insTerm: [insTerm ? insTerm : null, [Validators.required]],
      claimLimit: [claimLimit ? claimLimit : null, [Validators.required]],
      paymentTerm: [{ value: null, disabled: false }, [Validators.required]],
      paymentMethod: [{ value: null, disabled: false }, [Validators.required]],
      vehicleValueCalculation: [null],
    });
    this.hasCalculationModal = true;
    this.hasVehicleValueCalculation = true;

    // this.subscribeForCoveragesWhenFormValid(destroyed);
    return Promise.resolve();
  }

  resetSelectedCoverage(): void {
    if (this.selectedCoverage) {
      this.selectedCoverage.selected = false;
      this.selectedCoverage = null;
      if (this.coveragePrice) {
        this.coveragePrice = null;
      }
    }
  }

  /* Check if form is valid every change */
  validateVehicleInfo(): boolean {
    const nullValues = Object.keys(this.apkForm.value)
      .map((key) => this.apkForm.value[key])
      .filter((value) => value === null).length;
    this.formValidForCoverages = !nullValues;
    return !nullValues;
  }

  findCoverageCalculation(): void {
    const uiSelectedVariant = this.buttons.filter(
      (button) => button.isSelected
    );
    // resetting coverage selected option
    this.coverages.map((coverage) => (coverage.selected = false));

    if (this.coverages.length > 1) {
      if (uiSelectedVariant.length > 1) {
        console.log(
          'something went wrong before, you cannot choose two variants here'
        );
      } else {
        if (uiSelectedVariant) {
          this.coverages = this.coverages.filter(
            (coverage) => coverage.name === uiSelectedVariant[0].coverageName
          );
          this.selectCoverage();
        } else {
          console.log('error: you need to select coverage variant');
        }
      }
    } else {
      if (this.coverages.length) {
        this.selectCoverage();
      }
    }
  }

  selectCoverage(): void {
    this.selectedCoverage = this.coverages[0];
    this.selectedCoverage.selected = true;
    this.coveragePrice =
      Math.floor(this.selectedCoverage.magicFigures.min) +
      this.selectedCoverage.magicFigures.comms;
  }

  async fillFormData(policyData): Promise<void> {
    this.loading = true;

    this.confirmationService.confirm({
      message: this.transloco.translate('apk.summaryForm.labels.loading'),
      key: 'loadInProgress',
      acceptVisible: false,
      rejectVisible: false,
    });

    this.getPaymentMethodOptions();
    await this.checkPaymentTermsPromise(this.invoiceCoverageSelected());

    await Object.keys(this.apkForm.controls).forEach((key) => {
      switch (key) {
        case 'paymentMethod': {
          const paymentMethodOption = this.paymentMethodOptions.find(
            (opt) => opt.value === policyData.paymentMethod
          );
          const paymentMethod = this.apkForm.get('paymentMethod');

          paymentMethod.patchValue(paymentMethodOption);
          break;
        }
        case 'paymentTerm': {
          const paymentTermOption = this.paymentTermOptions.find(
            (opt) => opt.value === policyData.paymentTerm
          );
          const paymentTerm = this.apkForm.get('paymentTerm');

          paymentTerm.patchValue(paymentTermOption);
          break;
        }
        case 'vehicleRegDate':
        case 'vehiclePurchaseDate': {
          this.apkForm.get(key).patchValue(new Date(policyData[key]));
          break;
        }
        case 'vehicleValueCalculation': {
          this.apkForm
            .get('vehicleValueCalculation')
            .patchValue(policyData.vehiclePurchasePrice);
          break;
        }
        default: {
          this.apkForm.get(key).patchValue(policyData[key]);
        }
      }
    });

    this.apkForm.get('vehicleWeight').patchValue('');
    this.apkForm.get('vehicleType').patchValue('');
    this.apkForm.get('isElectric').patchValue(false);
    this.apkForm.get('usImported').patchValue(false);
    console.log(policyData);
    console.log(this.apkForm.value);
    console.log(this.summaryForm.value);

    const coveragePayloadDto: PolicyPriceCalculation = {
      productId: policyData.productId,
      vehicleRegDate: policyData.vehicleRegDate,
      vehiclePurchaseDate: policyData.vehiclePurchaseDate,
      vehiclePurchasePrice: policyData.vehiclePurchasePrice,
      selectedPaymentTerm: policyData.paymentTerm,
      insTerm: policyData.insTerm,
      vatIncluded: policyData.vatIncluded,
      vatReclaimable: policyData.vatReclaimable,
      claimLimit: policyData.claimLimit,
      vehicleValueCalculation: policyData.vehiclePurchasePrice,
    };

    this.getCoverages(coveragePayloadDto)
      .pipe(take(1))
      .subscribe((res) => {
        this.coverages = res;
        this.coveragesPendingRequest = false;
        this.findCoverageCalculation();
      });

    this.confirmationService.close();
    this.loading = false;
  }

  invoiceCoverageSelected(): boolean {
    if (this.buttons) {
      const selectedBtn = this.buttons.find((b) => b.isSelected);

      if (selectedBtn) {
        switch (selectedBtn.name) {
          case CoverageNameEnum.GapInvoice: {
            return true;
          }
          default: {
            return false;
          }
        }
      }

      return null;
    }

    return null;
  }

  onPaymentTermChange(): void {
    const paymentTerm = this.apkForm.get('paymentTerm');
    const paymentMethod = this.apkForm.get('paymentMethod');
    const bankTransferMethod = this.paymentMethodOptions.find(
      (opt) => opt.value === PaymentMethodEnum.bankTransfer
    );
    const payuInstallmentsMethod = this.paymentMethodOptions.find(
      (opt) => opt.value === PaymentMethodEnum.payuInstallments
    );

    switch (paymentTerm.value.label) {
      case 'LumpSum':
      case 'Annual': {
        this.togglePaymentMethods(false);
        paymentMethod.patchValue(bankTransferMethod);
        break;
      }

      case 'installments': {
        this.togglePaymentMethods(true);
        paymentMethod.patchValue(payuInstallmentsMethod);
        break;
      }
    }
  }

  togglePaymentMethods(installments: boolean): void {
    if (installments) {
      this.paymentMethodOptions.find(
        (opt) => opt.value === PaymentMethodEnum.payuInstallments
      ).disabled = false;
      this.paymentMethodOptions
        .filter((opt) => opt.value !== PaymentMethodEnum.payuInstallments)
        .forEach((opt) => {
          opt.disabled = true;
        });
    } else {
      this.paymentMethodOptions.find(
        (opt) => opt.value === PaymentMethodEnum.payuInstallments
      ).disabled = true;
      this.paymentMethodOptions
        .filter((opt) => opt.value !== PaymentMethodEnum.payuInstallments)
        .forEach((opt) => {
          opt.disabled = false;
        });
    }
  }

  initPreviewForm(destroyed: Subject<any>): void {
    if (this.apkForm.value.vehicleRegDate) {
      this.handleRegistrationDateLogic(destroyed);

      setTimeout(async () => {
        const selectedBtn = this.buttons.find((button) => button.isSelected);
        this.handleGapVariantChoose(selectedBtn, destroyed);

        if (history.state.step) {
          this.calculationDisabled = true;
          this.preview = this.setFormsPreviewOnly();

          if (selectedBtn.name === CoverageNameEnum.GapMaxAc) {
            await this.showMaxAcCascoForm();
          }

          this.hasSummary = true;
        } else {
          this.calculationDisabled = false;
          this.preview = false;
        }
      });
    }
  }

  selectGapVariant(button: Button): void {
    this.buttons.filter((b) => b.isSelected).map((b) => (b.isSelected = false));
    this.buttons.find((b) => b === button).isSelected = true;
  }

  handleMonthNavigator(minDate: Date, maxDate: Date): boolean {
    if (minDate && maxDate) {
      const minMaxDiff = moment(maxDate).diff(minDate, 'months');
      if (minMaxDiff < 11) {
        return false;
      }
    }

    return true;
  }

  handleYearNavigator(minDate: Date, maxDate: Date): boolean {
    if (minDate && maxDate) {
      const minMaxDiff = moment(maxDate).diff(minDate, 'months');
      if (minMaxDiff < 10) {
        return false;
      }
    }

    return true;
  }

  async getProposal(policyNo: string): Promise<Blob> {
    return await this.http
      .post<Blob>(
        `${this.apiBase}/policy/getProposal`,
        { policy_no: policyNo },
        { responseType: 'blob' as 'json' }
      )
      .toPromise();
  }

  async sendQuote(policyNo: string): Promise<void> {
    await this.http
      .post<Blob>(`${this.apiBase}/policy/sendQuote`, { policy_no: policyNo })
      .toPromise();
  }
}
